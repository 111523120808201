@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.pageWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.phoneAuthPage {
  max-width: 620px;
  width: 100%;
  margin: $spacing-5 auto 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: $spacing-5;
}

.phoneAuthPage_footer {
  margin-top: auto;
  display: none;
}

@include for-screen-from-480 {
  .phoneAuthPage {
    margin: 100px auto 50px;
  }

  .phoneAuthPage_footer {
    display: block;
  }
}

@include for-screen-from-1024 {
  .phoneAuthPage {
    padding: 0 $spacing-4;
  }
}

